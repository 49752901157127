@import '~bootstrap/scss/bootstrap';
@import "colors";

#news {
  background-color: #dddddd;
  .link-news {
    color: $text-muted;
    text-decoration: none !important;
    &:hover {
      color: $custom-color;
      text-decoration: none !important;
    }
  }
  .card-text {
    height: 175px;
  }
  .carousel {
    .carousel-inner {
      padding: 5%;
      .carousel-item {
        padding: 0 2%;
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      width: 5%;
    }
  }
}
