@import "colors";

nav {
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  .nav-item {
    .dropdown-menu {
      font-size: 12px;
      font-weight: 500;
      border-radius: 0;
      padding: 0 0.25rem;
      background-color: #efefef;
      border: solid 1px #cccccc;
      .dropdown-item {
        font-weight: 600;
        padding: 0.5rem 1.5rem;
        margin: 0.25rem 0;
        color: #222222;
        background-color: #cccccc;
        border: solid 1px #cccccc;
        &.active {
          color: $custom-color;
          background-color: #333333;
          border-color: #333333;
          &::after {
            content: '\02713';
            margin-left: 5px;
            font-weight: 700;
          }
        }
        &:hover{
          color: #222222;
          background-color: $custom-color;
          border-color: $custom-color;
        }
      }
    }
  }
}
