@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';

@import "common";
@import "navbar";
@import "profile";
@import "about";
@import "news";
@import "contact";
@import "error";
