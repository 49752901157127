@import "colors";

html, body {
  height: 100%;
  font-family: 'Roboto', Arial, sans-serif;
  line-height: 1.0;
}

body, .bg-dark {
  background-color: $background-color !important;
}

.pointer {
  cursor: pointer !important;
}
