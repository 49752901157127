@import "colors";

#about {
  background-color: #ffffff;
  p {
    color: #333333;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.2;
  }
  *[data-bs-toggle=tooltip] {
    cursor: pointer;
    font-weight: 700;
  }
  .tooltip {
    .tooltip-inner {
      font-size: 12px;
      font-weight: 300;
      text-align: justify;
      width: auto;
      padding: 20px;
      border-radius: 0;
    }
  }
  a.btn {
    font-size: 18px;
    font-weight: 700;
    &#btn_linkedin {
      background-color: #2672ae;
      border-color: #2672ae;
      color: #ffffff;
      border-radius: 0;
      box-shadow: none;
      &:hover {
        background-color: #ffffff;
        color: #2672ae;
      }
    }
    &#btn_github {
      background-color: $custom-color;
      border-color: $custom-color;
      color: #ffffff;
      border-radius: 0;
      box-shadow: none;
      &:hover {
        background-color: #ffffff;
        color: $custom-color;
      }
    }
    &#btn_cv {
      background-color: #666666;
      border-color: #666666;
      color: #ffffff;
      border-radius: 0;
      box-shadow: none;
      &:hover {
        background-color: #ffffff;
        color: #666666;
      }
    }
  }
}
