#profile {
  background-image: url('/assets/images/paris.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .col {
    padding: 15rem 0;
    h1 {
      color: #ffffff;
      font-size: 80px;
      font-weight: 900;
      text-shadow: 0 0 30px #000000;
      opacity: 0.80;
    }
    h2 {
      color: #ffffff;
      font-size: 40px;
      font-weight: 500;
      text-shadow: 0 0 30px #000000;
      opacity: 0.90;
    }
    h3 {
      color: #ffffff;
      font-size: 26px;
      font-weight: 300;
      text-shadow: 0 0 30px #000000;
      opacity: 1.00;
    }
  }
}
