@import "colors";

#error {
  h1 {
    color: #ffffff;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 40px;
    font-weight: 900;
    opacity: 0.80;
  }
  p {
    color: #999999;
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 20px;
    font-weight: 300;
  }
  a.btn {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: $custom-color;
    background-color: #333333;
    border-color: #333333;
    border-radius: 0;
    padding: 0.5rem 1rem;
    &:hover {
      color: #222222;
      background-color: $custom-color;
      border-color: $custom-color;
    }
  }
}
