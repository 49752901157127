@import "colors";

#contact {
  h2 {
    color: #ffffff;
    font-size: 80px;
    font-weight: 900;
    text-shadow: 0 0 30px #000000;
  }
  label {
    color: #CCCCCC;
    font-size: 16px;
    font-weight: 300;
    &.required:after {
      content: ' *'
    }
  }
  input[type=text],
  input[type=email],
  select,
  textarea {
    color: #999999;
    font-size: 16px;
    font-weight: 300;
    cursor: text;
  }
  label[for=contact_subject] {
    pointer-events: none;
  }
  button.btn {
    font-size: 18px;
    font-weight: 700;
    color: $custom-color;
    background-color: #333333;
    border-color: #333333;
    border-radius: 0;
  }
  button.btn:hover {
    color: #222222;
    background-color: $custom-color;
    border-color: $custom-color;
  }
  small.text-muted {
    color: #999999 !important;
    font-size: 75%;
    font-weight: 300;
  }
  .alert {
    font-weight: 300;
    &.alert-success {
      color: $custom-color;
      background-color: #333333;
      border-color: #333333;
      border-radius: 0;
    }
    &.alert-danger {
      background-color: transparent;
      border: none;
      padding: 0 0 0.75rem 0;
      .form-error-icon {
        color: #222222;
        background-color: $custom-color;
        padding: 0.3em 0.5em 0.25em;
        margin-right: 0.1em;
        border-radius: 0;
      }
      .form-error-message {
        color: $custom-color;
        &::before {
          content: ': ';
        }
      }
    }
  }
  .field {
    position: relative;
    margin-bottom: 1.5rem;
    &::before,
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #333333;
      content: '';
    }
    &::after {
      height: 2px;
      background-color: $custom-color;
      transform: scaleX(0);
      transform-origin: 0 50%;
      transition: transform 0.3s;
    }
    &.is-focused::after {
      transform: scaleX(1);
    }
    .field-label {
      display: block;
      position: relative;
      color: #777777;
      font-weight: bold;
      text-align: left !important;
      margin: 0;
      padding-left: 0.65rem;
      transform: translateY(30px);
      transform-origin: 0 50%;
      transition: 0.3s all ease;
      cursor: text;
    }
    &.has-label {
      .field-label {
        color: #ffffff;
        padding-left: 0;
        transform: translateY(-2px) scale(0.75);
        cursor: default;
      }
      .field-input {
        color: $custom-color;
        cursor: text;
        background-color: #444444;
        padding: 0.375rem 0.5rem;
      }
    }
    .field-input {
      font-weight: bold;
      padding: 0.375rem 0;
      background-color: #333333;
      border: none;
      border-radius: 0;
      box-shadow: none;
      transition: 0.3s all ease;
    }
    select.field-input {
      appearance: none;
      option {
        color: $custom-color !important;
        background-color: #333333 !important;
      }
    }
    &.is-focused {
      .field-label {
        padding-left: 0;
        color: #ffffff;
      }
      .field-input {
        color: $custom-color;
        cursor: text;
        background-color: #444444;
        padding: 0.375rem 0.5rem;
      }
    }
  }
}